var reInitGCaptcha;

(function($) {
    fn_nl_lib_anchor();

    if($('form[data-recaptcha]').length) {
        let recapchaCode = $('[data-recaptcha]').data('recaptcha');
        reInitGCaptcha = function() {
            $.getScript("https://www.google.com/recaptcha/api.js?render="+recapchaCode).done(function () {
                grecaptcha.ready(function () {
                    grecaptcha.execute(recapchaCode, {action: 'form'})
                        .then(function (token) {
                            $('#g-token').val(token);
                        });
                });
            });
        };
        reInitGCaptcha();
    }

    doc.on("[data-recaptcha]", "lib_reCapcha", function(selector) {
        $(selector).lib_reCapcha($(selector).data('recaptcha'));
    });

    $(".lib--parallax").find("img:not(.lazyload)").parent().parallax();

    doc.on('lazybeforeunveil', '.lazyload', function(e){
        if ($(e.target).parent().hasClass("lib--parallax")) {
            $(e.target).parent().parallax();
        }
        $(e.target).one('load', function() {
            if ($(this).parent().hasClass("lib--lazyload")) {
                $(this).parent().removeClass("lib--lazyload").addClass("lib--lazyloaded");
            }
        });
    });

    nl_lib_dialog.init("html", function(){
        doc.find(".lib--dialog .part_ui_btn").nl_lib_ripple();
    });
    $("[data-switch]").nl_lib_switch();
    $(".part_ui_btn").nl_lib_ripple();

    doc.on('click', '[href].ajax', function (e) {
        e.preventDefault();
        let $el = $(this);

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload);
            }
        });
    });

    doc.on('submit', 'form.ajax', function (e) {
        e.preventDefault();

        var submitButton = $(this).find('button[type="submit"]');
        if(submitButton.length) {
            submitButton.attr('disabled','disabled');
            submitButton.addClass('mod--loading');
        }

        let frm = $(this),
            formData = new FormData($(this)[0]);

        $.ajax({
            method: frm.attr('method'),
            url: frm.attr('action'),
            data: formData,
            processData: false,
            contentType: false,
            dataType: "json",
            success: function (payload) {
                fn_ajaxHandler(payload, function() {
                    if ($('form[data-recaptcha]').length) {
                        reInitGCaptcha();
                    }

                    if(submitButton.length) {
                        setTimeout(function() {
                            submitButton.removeAttr('disabled');
                            submitButton.removeClass('mod--loading');
                        },3000);
                    }
                });
            }
        });
    });

    doc.on('click', '[data-changegallery]', function (e) {
        e.preventDefault();
        let $el = $(this);

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                $('[data-changegallery]').removeClass('mod--active');
                $el.addClass('mod--active');
                fn_ajaxHandler(payload);
                sr.sync();
                $('#snippet-gallery').renderGallery();
            }
        });
    });

    doc.on('click', '[data-changeevents]', function (e) {
        e.preventDefault();
        let $el = $(this);

        $.ajax({
            url: $el.attr('href'),
            data: {ajax: +new Date},
            dataType: "json",
            success: function (payload) {
                $('[data-changeevents]').removeClass('mod--active');
                $el.addClass('mod--active');
                fn_ajaxHandler(payload);
                sr.sync();
            }
        });
    });


    let picture = document.createElement('picture'),
        strObj = '' + picture;
    if(strObj.indexOf("HTMLPictureElement") === -1 && !html.hasClass("ie"))
    {
        $.getScript(cdnjs.picturefill);
    }
    if (navigator.userAgent.indexOf('MSIE') !== -1 || navigator.appVersion.indexOf('Trident/') > 0) {
        html.addClass("ie11");
    }
    if ((navigator.userAgent.indexOf('Safari') != -1 && navigator.userAgent.indexOf('Chrome') == -1)) {
        html.addClass("safari");
    }
})(jQuery);
