(function($) {
    let $fn = $("#layout_header"),
        $nav = $("#layout_nav");

    if ($fn.length) {
        $('.ssm-nav').slideAndSwipe();

        let headroom = new Headroom($fn[0], {
            offset: 80,
            tolerance : {
                up : 5,
                down : 0
            },
            classes: {
                "initial" : "headroom",
                "pinned": "headroom--pinned",
                "unpinned": "headroom--unpinned",
                "top" : "headroom--top",
                "notTop" : "headroom--not-top"
            }
        });
        headroom.init();

        if ($nav.length) {
            $nav.on("click","[data-submenu-toggle]",function () {
                let $el = $(this);
                $el.removeClass("mod--active").siblings("a").addClass("mod--active");
                if($el.data("submenu-toggle")==="show"){
                    $el.siblings(".elm_nav_sub").addClass("mod--active");
                }
                else {
                    $el.siblings(".elm_nav_sub").removeClass("mod--active");
                }
            })
        }
    }
})(jQuery);
